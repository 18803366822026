import React from 'react'
import { Helmet } from 'react-helmet'

import Header from '../components/header/header'
import PrivacyPolicy from '../components/pages/privacy-policy'
import Footer from '../components/layout/footer'
import CookieBox from '../components/cookie/cookie-box'
const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>DARC-EXP | PRIVACY POLICY</title>
        <body className="imprint" />
      </Helmet>
      <div className="imprintpage">
        <Header darkTheme />
        <CookieBox />
        <PrivacyPolicy />
        <Footer />
      </div>
    </>
  )
}

export default PrivacyPolicyPage
