import React from 'react'
import Container from '../layout/container'
import PageContainer from './page-container'
import './imprint.scss'

const PrivacyPolicy = () => (
  <PageContainer>
    <div className="imprint">
      <h3>Privacy policy</h3>
      <div className="imprint-container">
        <div className="imprint-content">
          <div className="content">
            <p>
              Mit dieser Datenschutz-Erklärung informieren wir Sie über die
              Verarbeitung von personenbezogenen Daten bei der Nutzung unserer
              Internetseite und unseres Onlineshops. Sie können diese
              Unterrichtung jederzeit auf unserer Webseite abrufen.
            </p>
          </div>
          <div className="title">
            1. Grundsätzliche Angaben zur Datenverarbeitung
          </div>
          <div className="content">
            <p>
              Die verwendeten Begriffe, wie beispielsweise „Verantwortlicher“,
              „personenbezogene Daten“ oder deren „Verarbeitung“ entsprechen den
              Definitionen der DSGVO. Alle Begriffsdefinitionen des Art. 4 der
              Datenschutzgrundverordnung finden Sie unter folgendem Link auf
              Seite 33.
            </p>
          </div>
          <div className="title">2. Verantwortlicher</div>
          <div className="content">
            <p>Verantwortlicher für die Datenverarbeitung ist</p>
            <p>
              DARC EXP GmbH, vertreten durch Tomas Konecny, Daimlerstraße 39,
              73037 Göppingen,{' '}
            </p>
            <a href="mailto:info@darc-exp.com">info@darc-exp.com</a>
          </div>
          <div className="title">
            3. Datenverarbeitung beim Besuch der Internetseite
          </div>
          <div className="content">
            <p>
              Bei jedem Aufruf unserer Internetseite, also auch bei einer rein
              informatorischen Nutzung, werden verschiedene Daten und
              Informationen automatisch von ihrem Browser (Programm zur
              Darstellung von Webseiten) an den Server, auf dem sich unsere
              Internetseite befindet, übermittelt und in sogenannten Server-Log
              Files (Protokolldateien) des Servers gespeichert. Erfasst und
              gespeichert werden
            </p>
            <ul>
              <li>die IP-Adresse (Internetprotokoll-Adresse),</li>
              <li>
                das Datum und die Uhrzeit des Zugriffs auf unsere Internetseite,
              </li>
              <li>die Zeitzonendifferenz zur Greenwich Mean Time (GMT),</li>
              <li>den Standort (Land, Bundesland, Region, Stadt)</li>
              <li>
                die für den Zugriff auf unsere Internetseite verwendete
                Browsersoftware, deren Version und Sprache,
              </li>
              <li>
                das Betriebssystem des Geräts, von dem aus unsere Internetseite
                aufgerufen wird,
              </li>
              <li>
                der Internet-Service-Provider (Internetdienstanbieter) des
                zugreifenden Geräts,
              </li>
              <li>
                der Inhalt der Anforderung (Inhalte und Dateien der aufgerufenen
                Seiten),
              </li>
              <li>die jeweils übertragene Datenmenge,</li>
              <li>
                die Meldung darüber, ob der Zugriff erfolgreich war
                (Zugriffsstatus/http-Statuscode),
              </li>
              <li>
                die Internetseite, von welcher auf unsere Internetseite
                zugegriffen wird.
              </li>
            </ul>
            <p>
              Die Bereitstellung der Daten ist weder vertraglich noch gesetzlich
              vorgeschrieben. Sie ist nicht für einen Vertragsschluss
              erforderlich und Sie sind nicht verpflichtet, die Daten
              bereitzustellen.
            </p>
            <p>
              Die Speicherung der IP-Adresse – und sei dies auch nur für eine
              kurze Zeit – ist aufgrund der Funktionsweise des Internets jedoch
              technisch notwendig. Die zuvor genannten Informatio- nen und Daten
              werden von uns nicht genutzt, um Rückschlüsse auf Sie zu ziehen
              und Sie zu identifizieren. Daten, die eine Identifikation Ihrer
              Person möglich machen, werden frühestmöglich anonymisiert.
            </p>
            <p>
              Die oben aufgeführten Daten und Informationen werden von uns
              ausschließlich erhoben, um Ihnen unsere Webseite anzuzeigen und
              deren Stabilität und Sicherheit zu gewährleisten und diesbezüglich
              unsere Webseite zu optimieren. Darüber hinaus nutzen wir die Daten
              für die anonyme, statistische Auswertung Ihrer Bewegungen auf
              unserer Webseite. Die Logfiles werden getrennt von Ihren weiteren
              personenbezogenen Daten, die Sie uns im Rahmen des Besuches
              unserer Seiten oder einer Bestellung ggf. selbst zur Verfügung
              gestellt haben, gespeichert und mit diesen nicht zusammengeführt.
            </p>
            <p>
              Rechtsgrundlage für die Erhebung der genannten Daten ist in
              Einklang mit Art. 6 Abs. 1 f) DSGVO unser berechtigtes Interesse
              an der Funktionsfähigkeit und Sicherheit unserer Webseite. Darüber
              hinaus haben wir ein berechtigtes Interesse daran, die anonymen
              bzw. anonymisierten Daten zur Auswertung des Nutzerverhaltens auf
              unseren Seiten zu verwenden, um die Wirksamkeit unserer
              Webseitengestaltung und unseres Webseitenaufbaus bewerten zu
              können.
            </p>
            <p>Die Logfiles werden nach 30 Tagen gelöscht.</p>
          </div>
          <div className="title">
            5. Datenerhebung im Rahmen der Abwicklung einer Bestellung
          </div>
          <div className="content">
            <p>
              Wir erheben von Ihnen Daten im Rahmen einer Bestellung und zwar:
            </p>
            <ul>
              <li>Ihren Namen,</li>
              <li>Ihre Adresse,</li>
              <li>eine abweichende Lieferadresse, wenn Sie diese angeben,</li>
              <li>Ihre E-Mail-Adresse</li>
              <li>Angaben zum gekauften Gegenstand,</li>
              <li>Datum und Uhrzeit des Kaufs,</li>
              <ul>
                <li>
                  die von Ihnen gewünschte Zahlungsart,Zahlungsdaten, nämlich
                </li>
                <li>die Information, welche Zahlungsart Sie nutzen</li>
                <li>
                  sowie wenn Sie mit Kreditkarte zahlen, den
                  Kreditkarteninhaber, die Kreditkartennummer und das
                  Ablaufdatum der Kreditkarte,
                </li>
                <li>
                  wenn Sie per Lastschrift bezahlen und uns eine
                  Einzugsermächtigung erteilen, Kontoinhaber, Kontonummer,
                  Bankleitzahl oder IBAN und BIC,
                </li>
                <li>wenn Sie per Paypal zahlen, den Kontoinhaber</li>
              </ul>
            </ul>
            <p>
              Die Daten erheben wir zur Abwicklung des Kaufvertrages
              entsprechend Artikel 6 Absatz 1 Satz 1 Buchstabe b) DSGVO. Die
              Daten werden für die Dauer der gesetzlichen Aufbewahrungspflichten
              von derzeit 10 Jahren von uns gespeichert und anschließend
              gelöscht.{' '}
            </p>
            <p>
              Wir verwenden die Daten ferner zur Abwicklung von
              Gewährleistungsansprüchen sowie zur Geltendmachung, Ausübung und
              Verteidigung zivilrechtlicher Ansprüche. Rechtsgrundlage hierfür
              ist Art. 6 Absatz 1 Satz 1 Buchstabe f) DSGVO in Verbindung mit §
              24 Absatz 1 Satz 1 Nummer 2 BDSG. Das berechtigte Interesse an der
              Verarbeitung der Daten besteht darin, etwaige
              Gewährleistungsansprüche prüfen und ordnungsgemäß abwickeln zu
              können sowie etwaige zivilrechtliche Ansprüche gegenüber Käufern
              durchsetzen bzw. diesen entgegentreten zu können. Zu diesen
              Zwecken speichern wir die Daten, bis alle gegenseitigen Ansprüche
              verjährt sind und löschen die Daten in der Regel spätestens vier
              Jahre nach dem Abschluss des Kaufvertrags, soweit nicht
              ausnahmsweise eine längere Aufbewahrung erforderlich ist.
            </p>
          </div>
          <div className="title">6. Weitergabe von Daten an Dritte</div>
          <div className="content">
            <p>6.1 Im Rahmen der Abwicklung der Bestellung</p>
            <p>
              Im Rahmen der Abwicklung des Kaufvertrages übermitteln wir einige
              der Sie betreffenden Daten an Transportunternehmen (Post-,
              Paketdienste und Speditionen) sowie die im Rah- men der
              Kaufpreiszahlung beauftragten Zahlungsdienstleister.
              Rechtsgrundlage hierfür ist Artikel 6 Absatz 1 Satz 1 Buchstabe b)
              DSGVO.
            </p>
            <p>
              Für den Versand der Kaufsache an Sie beauftragen wir regelmäßig
              folgende Unternehmen:
            </p>
            <ul>
              <li>Deutsche Post AG, Charles-de-Gaulle-Straße 20, 53113 Bonn</li>
              <li>DHL Paket GmbH, Sträßchensweg 10, 53113 Bonn</li>
            </ul>
            <p>
              An diese Unternehmen geben wir Ihren Namen sowie Ihre Adresse bzw.
              die von Ihnen an- gegebene Lieferadresse weiter. Wenn wir zur
              Lieferung größerer Gegenstände eine Spedition beauftragten, geben
              wir auch Ihre E- Mail-Adresse zur Vereinbarung eines Liefertermins
              an die von uns beauftragte Spedition weiter.
            </p>
            <p>
              Für die Abwicklung der Kaufpreiszahlung geben wir auf Grundlage
              von Artikel 6 Absatz 1 Satz 1 Buchstabe b) DSGVO die von Ihnen
              angegebenen Zahlungsdaten an mit der Abwicklung beauftragte
              Zahlungsdienstleister weiter:
            </p>
            <ul>
              <li>
                bei Zahlung per Banküberweisung: Kreissparkasse Göppingen,
                Marktstraße 2, 73033 Göppingen
              </li>
              <li>
                bei Zahlung mit Kreditkarte: Stripe Inc., 354 Oyster Point
                Boulevard, South San Francisco, California, 94080, USA
              </li>
              <li>
                bei Zahlung per PayPal, Kreditkarte via PayPal, Lastschrift via
                PayPal oder Kauf auf Rechnung via PayPal: PayPal (Europe) S.à
                r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg,
              </li>
              <li>
                Amazon Payments: Amazon Payments Europe S.C.A., 38 avenue John.
                F. Kennedy, L-1855 Luxemburg
              </li>
            </ul>
            <p>
              Außerdem werden die Zahlungsdaten an Ihr Kreditinstitut und bei
              Kreditkartenzahlung an Ihren Kreditkartenanbieter weitergeben.
            </p>
            <p>
              Wir geben die oben unter 4. genannten Daten außerdem zu Zwecken
              der Buchhaltung und Erstellung der Steuererklärung an eine
              Steuerberatungskanzlei Marion Claußnitzer - Steuerberaterin,
              Friedrich-Engels-Straße 10, 01809 Heidenau weiter. Die Weitergabe
              erfolgt auf der Grundlage eines berechtigten Interesses gemäß
              Artikel 6 Absatz 1 Satz 1 Buchstabe f) DSGVO. Wir haben ein
              berechtigtes Interesse daran, die Buchhaltung und Steuerberatung
              mit Hilfe einer fachkundigen Person durchzuführen.
            </p>
            <p>
              Im Übrigen geben wir die Sie betreffenden Daten nur weiter, wenn
              Sie in die Weitergabe gemäß Artikel 6 Absatz 1 Satz 1 Buchstabe a)
              DSGVO gesondert eingewilligt haben.
            </p>
            <p>6.2 Google Analytics </p>
            <p>a) Umfang der Verarbeitung personenbezogener Daten</p>
            <p>
              Diese Webseite nutzt Funktionen des Webanalysedienstes Google
              Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway
              Mountain View, CA 94043, USA.
            </p>
            <p>
              Google Analytics verwendet so genannte „Cookies“. Das sind
              Textdateien, die auf Ihrem Computer gespeichert werden und die
              eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die
              durch den Cookie erzeugten Informationen über Ihre Benutzung
              dieser Webseite werden in der Regel an einen Server von Google in
              den USA übertragen und dort gespeichert.
            </p>
            <p>IP Anonymisierung</p>
            <p>
              Wir haben auf dieser Webseite die Funktion IP-Anonymisierung
              aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in
              Ausnahmefällen wird die volle IP-Adresse an einen Server von
              Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Webseite wird Google diese Informationen
              benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports
              über die Webseitenaktivitäten zusammenzustellen und um weitere mit
              der Webseitennutzung und der Internetnutzung verbundene
              Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.
              Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
              IP-Adresse wird nicht mit anderen Daten von Google
              zusammengeführt.
            </p>
            <p>Browser Plugin</p>
            <p>
              Sie können die Speicherung der Cookies durch eine entsprechende
              Einstellung Ihrer Browser-Software verhindern; wir weisen Sie
              jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
              sämtliche Funktionen dieser Webseite vollumfänglich werden nutzen
              können. Sie können darüber hinaus die Erfassung der durch den
              Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten
              (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
              Daten durch Google verhindern, indem Sie das unter dem folgenden
              Link verfügbare Browser-Plugin herunterladen und installieren:{' '}
            </p>
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            <p>.</p>
            <p>
              b) Rechtsgrundlage für die Verarbeitung personenbezogener Daten
            </p>
            <p>
              Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen
              einer Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
            </p>
            <p>c) Zweck der Datenverarbeitung</p>
            <p>
              Diese Webseite nutzt Google Analytics, um eine Analyse der
              Benutzung zu ermöglichen. Die Verarbeitung der personenbezogenen
              Daten der Nutzer ermöglicht uns eine Analyse des Surfverhaltens
              unserer Nutzer. Wir sind durch die Auswertung der gewonnenen Daten
              in der Lage, Informationen über die Nutzung der einzelnen
              Komponenten unserer Webseite zusammenzustellen. Dies hilft uns
              dabei, unsere Webseite und deren Nutzerfreundlichkeit stetig zu
              verbessern.
            </p>
            <p>d) Dauer der Speicherung</p>
            <p>
              In der Regel werden die erhobenen Daten gelöscht, sobald sie nicht
              mehr benötigt werden. Eine Löschung erfolgt spätestens nach 38
              Monaten.
            </p>
            <p>e) Widerspruchs- und Beseitigungsmöglichkeit</p>
            <p>
              Der Datenerhebung kann jederzeit mit Wirkung für die Zukunft
              widersprochen werden, indem Sie unter folgendem Link das
              Browser-Add-on zur Deaktivierung von Google Analytics
              herunterladen und installieren:{' '}
            </p>
            <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
              https://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            <p>.</p>
            <p>6.3 Facebook</p>
            <p>
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Komponenten des Unternehmens Facebook integriert.
              Facebook ist ein soziales Netzwerk.
            </p>
            <p>
              Ein soziales Netzwerk ist ein im Internet betriebener sozialer
              Treffpunkt, eine Online-Gemeinschaft, die es den Nutzern in der
              Regel ermöglicht, untereinander zu kommunizieren und im virtuellen
              Raum zu interagieren. Ein soziales Netzwerk kann als Plattform zum
              Austausch von Meinungen und Erfahrungen dienen oder ermöglicht es
              der Internetgemeinschaft, persönliche oder unternehmensbezogene
              Informationen bereitzustellen. Facebook ermöglicht den Nutzern des
              sozialen Netzwerkes unter anderem die Erstellung von privaten
              Profilen, den Upload von Fotos und eine Vernetzung über
              Freundschaftsanfragen.
            </p>
            <p>
              Betreibergesellschaft von Facebook ist die Facebook, Inc., 1
              Hacker Way, Menlo Park, CA 94025, USA. Für die Verarbeitung
              personenbezogener Daten Verantwortlicher ist, wenn eine betroffene
              Person außerhalb der USA oder Kanada lebt, die Facebook Ireland
              Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2,
              Ireland.
            </p>
            <p>
              Durch jeden Aufruf einer der Einzelseiten dieser Internetseite,
              die durch den für die Verarbeitung Verantwortlichen betrieben wird
              und auf welcher eine Facebook-Komponente (Facebook-Plug-In)
              integriert wurde, wird der Internetbrowser auf dem
              informationstechnologischen System der betroffenen Person
              automatisch durch die jeweilige Facebook-Komponente veranlasst,
              eine Darstellung der entsprechenden Facebook-Komponente von
              Facebook herunterzuladen. Eine Gesamtübersicht über alle
              Facebook-Plug-Ins kann unter{' '}
            </p>
            <a href="https://developers.facebook.com/docs/plugins/?locale=de_DE">
              https://developers.facebook.com/docs/plugins/?locale=de_DE
            </a>
            <p>
              {' '}
              abgerufen werden. Im Rahmen dieses technischen Verfahrens erhält
              Facebook Kenntnis darüber, welche konkrete Unterseite unserer
              Internetseite durch die betroffene Person besucht wird.
            </p>
            <p>
              Sofern die betroffene Person gleichzeitig bei Facebook eingeloggt
              ist, erkennt Facebook mit jedem Aufruf unserer Internetseite durch
              die betroffene Person und während der gesamten Dauer des
              jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete
              Unterseite unserer Internetseite die betroffene Person besucht.
              Diese Informationen werden durch die Facebook-Komponente gesammelt
              und durch Facebook dem jeweiligen Facebook-Account der betroffenen
              Person zugeordnet. Betätigt die betroffene Person einen der auf
              unserer Internetseite integrierten Facebook-Buttons,
              beispielsweise den „Gefällt mir“-Button, oder gibt die betroffene
              Person einen Kommentar ab, ordnet Facebook diese Information dem
              persönlichen Facebook-Benutzerkonto der betroffenen Person zu und
              speichert diese personenbezogenen Daten.
            </p>
            <p>
              Facebook erhält über die Facebook-Komponente immer dann eine
              Information darüber, dass die betroffene Person unsere
              Internetseite besucht hat, wenn die betroffene Person zum
              Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei
              Facebook eingeloggt ist; dies findet unabhängig davon statt, ob
              die betroffene Person die Facebook-Komponente anklickt oder nicht.
              Ist eine derartige Übermittlung dieser Informationen an Facebook
              von der betroffenen Person nicht gewollt, kann diese die
              Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf
              unserer Internetseite aus ihrem Facebook-Account ausloggt.
            </p>
            <p>Die von Facebook veröffentlichte Datenrichtlinie, die unter </p>
            <a href="https://de-de.facebook.com/about/privacy/">
              https://de-de.facebook.com/about/privacy/
            </a>
            <p>
              {' '}
              abrufbar ist, gibt Aufschluss über die Erhebung, Verarbeitung und
              Nutzung personenbezogener Daten durch Facebook. Ferner wird dort
              erläutert, welche Einstellungsmöglichkeiten Facebook zum Schutz
              der Privatsphäre der betroffenen Person bietet. Zudem sind
              unterschiedliche Applikationen erhältlich, die es ermöglichen,
              eine Datenübermittlung an Facebook zu unterdrücken. Solche
              Applikationen können durch die betroffene Person genutzt werden,
              um eine Datenübermittlung an Facebook zu unterdrücken.
            </p>
            <p>6.4 Instagram</p>
            <p>
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Komponenten des Dienstes Instagram integriert.
              Instagram ist ein Dienst, der als audiovisuelle Plattform zu
              qualifizieren ist und den Nutzern das Teilen von Fotos und Videos
              und zudem eine Weiterverbreitung solcher Daten in anderen sozialen
              Netzwerken ermöglicht.
            </p>
            <p>
              Betreibergesellschaft der Dienste von Instagram ist die Instagram
              LLC, 1 Hacker Way, Building 14 First Floor, Menlo Park, CA, USA.
            </p>
            <p>
              Durch jeden Aufruf einer der Einzelseiten dieser Internetseite,
              die durch den für die Verarbeitung Verantwortlichen betrieben wird
              und auf welcher eine Instagram-Komponente (Insta-Button)
              integriert wurde, wird der Internetbrowser auf dem
              informationstechnologischen System der betroffenen Person
              automatisch durch die jeweilige Instagram-Komponente veranlasst,
              eine Darstellung der entsprechenden Komponente von Instagram
              herunterzuladen. Im Rahmen dieses technischen Verfahrens erhält
              Instagram Kenntnis darüber, welche konkrete Unterseite unserer
              Internetseite durch die betroffene Person besucht wird.
            </p>
            <p>
              Sofern die betroffene Person gleichzeitig bei Instagram eingeloggt
              ist, erkennt Instagram mit jedem Aufruf unserer Internetseite
              durch die betroffene Person und während der gesamten Dauer des
              jeweiligen Aufenthaltes auf unserer Internetseite, welche konkrete
              Unterseite die betroffene Person besucht. Diese Informationen
              werden durch die Instagram-Komponente gesammelt und durch
              Instagram dem jeweiligen Instagram-Account der betroffenen Person
              zugeordnet. Betätigt die betroffene Person einen der auf unserer
              Internetseite integrierten Instagram-Buttons, werden die damit
              übertragenen Daten und Informationen dem persönlichen
              Instagram-Benutzerkonto der betroffenen Person zugeordnet und von
              Instagram gespeichert und verarbeitet.
            </p>
            <p>
              Instagram erhält über die Instagram-Komponente immer dann eine
              Information darüber, dass die betroffene Person unsere
              Internetseite besucht hat, wenn die betroffene Person zum
              Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei
              Instagram eingeloggt ist; dies findet unabhängig davon statt, ob
              die betroffene Person die Instagram-Komponente anklickt oder
              nicht. Ist eine derartige Übermittlung dieser Informationen an
              Instagram von der betroffenen Person nicht gewollt, kann diese die
              Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf
              unserer Internetseite aus ihrem Instagram-Account ausloggt.
            </p>
            <p>
              Weitere Informationen und die geltenden Datenschutzbestimmungen
              von Instagram können unter{' '}
            </p>
            <a href="https://help.instagram.com/155833707900388">
              https://help.instagram.com/155833707900388
            </a>
            <p> und </p>
            <a href="https://www.instagram.com/about/legal/privacy/">
              https://www.instagram.com/about/legal/privacy/
            </a>
            <p> abgerufen werden</p>
            <p>6.5 Youtube</p>
            <p>
              Der für die Verarbeitung Verantwortliche hat auf dieser
              Internetseite Komponenten von YouTube integriert. YouTube ist ein
              Internet-Videoportal, dass Video-Publishern das kostenlose
              Einstellen von Videoclips und anderen Nutzern die ebenfalls
              kostenfreie Betrachtung, Bewertung und Kommentierung dieser
              ermöglicht. YouTube gestattet die Publikation aller Arten von
              Videos, weshalb sowohl komplette Film- und Fernsehsendungen, aber
              auch Musikvideos, Trailer oder von Nutzern selbst angefertigte
              Videos über das Internetportal abrufbar sind.
            </p>
            <p>
              Betreibergesellschaft von YouTube ist die YouTube, LLC, 901 Cherry
              Ave., San Bruno, CA 94066, USA. Die YouTube, LLC ist einer
              Tochtergesellschaft der Google Inc., 1600 Amphitheatre Pkwy,
              Mountain View, CA 94043-1351, USA.
            </p>
            <p>
              Durch jeden Aufruf einer der Einzelseiten dieser Internetseite,
              die durch den für die Verarbeitung Verantwortlichen betrieben wird
              und auf welcher eine YouTube-Komponente (YouTube-Video) integriert
              wurde, wird der Internetbrowser auf dem
              informationstechnologischen System der betroffenen Person
              automatisch durch die jeweilige YouTube-Komponente veranlasst,
              eine Darstellung der entsprechenden YouTube-Komponente von YouTube
              herunterzuladen. Weitere Informationen zu YouTube können unter{' '}
            </p>
            <a href="https://www.youtube.com/yt/about/de/">
              https://www.youtube.com/yt/about/de/
            </a>
            <p>
              {' '}
              abgerufen werden. Im Rahmen dieses technischen Verfahrens erhalten
              YouTube und Google Kenntnis darüber, welche konkrete Unterseite
              unserer Internetseite durch die betroffene Person besucht wird.
            </p>
            <p>
              Sofern die betroffene Person gleichzeitig bei YouTube eingeloggt
              ist, erkennt YouTube mit dem Aufruf einer Unterseite, die ein
              YouTube-Video enthält, welche konkrete Unterseite unserer
              Internetseite die betroffene Person besucht. Diese Informationen
              werden durch YouTube und Google gesammelt und dem jeweiligen
              YouTube-Account der betroffenen Person zugeordnet.
            </p>
            <p>
              YouTube und Google erhalten über die YouTube-Komponente immer dann
              eine Information darüber, dass die betroffene Person unsere
              Internetseite besucht hat, wenn die betroffene Person zum
              Zeitpunkt des Aufrufs unserer Internetseite gleichzeitig bei
              YouTube eingeloggt ist; dies findet unabhängig davon statt, ob die
              betroffene Person ein YouTube-Video anklickt oder nicht. Ist eine
              derartige Übermittlung dieser Informationen an YouTube und Google
              von der betroffenen Person nicht gewollt, kann diese die
              Übermittlung dadurch verhindern, dass sie sich vor einem Aufruf
              unserer Internetseite aus ihrem YouTube-Account ausloggt.
            </p>
            <p>
              Die von YouTube veröffentlichten Datenschutzbestimmungen, die
              unter{' '}
            </p>
            <a href="https://www.google.de/intl/de/policies/privacy/">
              https://www.google.de/intl/de/policies/privacy/
            </a>
            <p>
              {' '}
              abrufbar sind, geben Aufschluss über die Erhebung, Verarbeitung
              und Nutzung personenbezogener Daten durch YouTube und Google.
            </p>
          </div>
          <div className="title">7. Kontaktaufnahme mit uns</div>
          <div className="content">
            <p>
              Sie haben die Möglichkeit, uns eine Nachricht zu schicken, indem
              Sie das zur Verfügung gestellte Kontaktformular nutzen, uns eine
              E-Mail an die im Impressum oder in dieser Datenschutzerklärung
              genannte E-Mail-Adresse senden, uns ein Fax senden oder uns unter
              der angegebenen Telefonnummer anrufen. Dabei verarbeiten wir
            </p>
            <ul>
              <li>
                bei Nutzung des Kontaktformulars Ihren Namen und den Inhalt des
                von Ihnen in das Kontaktformular eingefügten Textes,
              </li>
              <li>
                wenn Sie uns eine E-Mail senden, Ihre dafür genutzte
                E-Mail-Adresse, das Datum und die Uhrzeit der Anfrage sowie die
                Inhalte des von Ihnen in der Betreffzeile und in der Nachricht
                eingefügten Textes,
              </li>
              <li>
                wenn Sie uns telefonisch kontaktieren, die Rufnummer des von
                Ihnen genutzten Anschlusses, soweit diese übermittelt wird, das
                Datum und die Uhrzeit des Anrufs sowie die Daten, die Sie uns
                mündlich übermitteln.
              </li>
            </ul>
            <p>
              Wenn sich Ihre Anfrage auf die Erfüllung eines bereits zwischen
              Ihnen und uns geschlossenen Vertrages bezieht (z. B. eine Frage
              zur Lieferung des von Ihnen gekauften Gegenstandes) oder zur
              Durchführung von vorvertraglichen Maßnahmen erforderlich ist,
              verarbeiten wir die Daten auf der Grundlage von Artikel 6 Abs. 1
              Satz 1 Buchstabe b) DSGVO. Soweit es nicht um vertragliche oder
              vorvertragliche Angelegenheiten geht, verarbeiten wir die
              personenbezogenen Daten auf der Grundlage von Artikel 6 Abs. 1
              Satz 1 Buchstabe f) DSGVO.
            </p>
            <p>
              Wir haben ein berechtigtes Interesse daran, die von Ihnen an uns
              übermittelte Mitteilung inhaltlich zu bearbeiten und Ihnen zu
              antworten.
            </p>
            <p>
              Wir speichern die von Ihnen in diesem Zusammenhang erhobenen Daten
              bis zur Beantwortung Ihrer Anfrage, bei vertraglichen Anfragen,
              bis keine gegenseitigen Ansprüche mehr bestehen und gesetzliche
              Aufbewahrungspflichten abgelaufen sind.
            </p>
          </div>
          <div className="title">8. Cookies</div>
          <div className="content">
            <p>
              Auf unserer Internetseite werden Cookies eingesetzt, die für die
              Nutzung der Internetseite technisch erforderlich sind. Cookies
              sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und
              die Wiedererkennung Ihres Browsers ermöglichen.
            </p>
            <p>
              Wir setzten dabei sogenannte "Session-Cookies" ein, die
              ausschließlich für die Dauer der Nutzung einer unserer
              Internetseiten zwischengespeichert und anschließend automatisch
              gelöscht werden. Die Session-Cookies sorgen z.B. dafür, dass
              Waren, die Sie in den Warenkorb gelegt haben, auch bei einem
              weiteren Surfen auf unseren Webseiten in dem Warenkorb enthalten
              bleiben.
            </p>
            <p>
              Rechtsgrundlage für die Nutzung der Cookies ist Art. 6 Abs. 1
              Buchstabe f) DSGVO. Unser berechtigtes Interesse am Einsatz von
              Warenkorb-Cookies besteht darin, die Webseite und den Onlineshop
              funktional und leicht handhabbar zu gestalten.
            </p>
            <p>
              Schließen Sie die Browser, das Browserfenster oder rufen Sie eine
              andere Website auf, so wird der Warenkorb zurückgesetzt. Die
              bereits eingelegten Waren müssten bei einem späteren Besuch erneut
              in den Warenkorb gelegt werden.
            </p>
            <p>
              Durch eine Änderung der Browsereinstellungen kann verhindert
              werden, dass Cookies auf dem Rechner der Nutzer unseres
              Internet-Angebots abgespeichert werden. Das Speichern von Cookies
              kann verhindert werden, indem in den Browser-Einstellungen „keine
              Cookies akzeptieren“ ausgewählt wird. Dadurch kann der
              Funktionsumfang unseres Onlineangebotes eingeschränkt werden.
            </p>
          </div>
          <div className="title">9. Betroffenenrechte</div>
          <div className="content">
            <p>
              Sie haben als von der Datenverarbeitung betroffene Person nach
              Maßgabe der jeweiligen gesetzlichen Regelungen folgende Rechte
              gegenüber dem HDE:
            </p>
            <ul>
              <li>Recht auf Auskunft nach Art. 15 DSGVO</li>
              <li>Recht auf Berichtigung nach Art. 16 DSGVO</li>
              <li>Recht auf Löschung nach Art. 17 DSGVO</li>
              <li>
                Recht auf Einschränkung der Datenverarbeitung nach Art. 18 DSGVO
              </li>
              <li>Recht auf Datenübertragbarkeit nach Art. 20 DSGVO</li>
              <li>Widerspruchsrecht nach Art. 21 DSGVO</li>
            </ul>
          </div>
          <div className="title">10. Stand</div>
          <div className="content">
            <p>
              <p>
                Diese Datenschutzerklärung hat den Stand vom 12.07.2022.
                Aufgrund von Änderungen unserer Verarbeitungstätigkeiten und
                einer Weiterentwicklung des Datenschutzrechts kann eine Änderung
                der Datenschutzerklärung erforderlich werden. Die aktuelle
                Fassung finden Sie jeweils auf unserer Homepage.
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  </PageContainer>
)

export default PrivacyPolicy
